
  import { groupBy } from 'lodash';

  export default {
    name: 'CategoriesList1',
    components: {
      NestedCategory: () => import('./NestedCategory'),
    },
    data() {
      return {
        search: '',
      };
    },
    props: {
      containerClass: {
        type: String,
        default: '',
      },
      rowClass: {
        type: String,
        default: '',
      },
      columnClass: {
        type: String,
        default: '',
      },
      filterable: {
        type: Boolean,
        default: false,
      },
      urlPrefix: {
        type: String,
        default: '',
      },
      showOnlyRoot: {
        type: Boolean,
        default: false,
      },
      groupByFirstLetter: {
        type: Object,
        default: () => ({}),
      },
      categoriesList: {
        type: Object | Array,
        default: () => [],
      },
    },
    computed: {
      filteredCategories() {
        const text = this.search && this.search.toLowerCase();
        return text ? this.filterCategories(text) : this.categoriesList;
      },
      groupedCategories() {
        const iteratee = (category) => category.name[0].toUpperCase();
        return groupBy(this.filteredCategories, iteratee);
      },
      isGroupByFirstLetter() {
        return this.groupByFirstLetter.enabled;
      },
      categoryUrlPrefix() {
        const prefix = this.urlPrefix || 'cat/';
        return /\/$/.test(prefix) ? prefix : `${prefix}/`;
      },
    },
    methods: {
      filterCategories(text) {
        const predicate = (category) => category.name && category.name.toLowerCase().includes(text);

        if (this.isGroupByFirstLetter) {
          return this.categoriesList.filter(predicate);
        } else {
          return this.categoriesList.reduce((categories, parentCategory) => {
            const category = { ...parentCategory };
            category.childCategories = category.childCategories.filter(predicate);
            if ((!this.showOnlyRoot && category.childCategories.length > 0) || predicate(category)) {
              categories.push(category);
            }
            return categories;
          }, []);
        }
      },
    },
  };
