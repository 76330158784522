
  import orderAdjustmentsMixin from '@/components/checkout/orderAdjustmentsMixin';
  import cartItemsMixin from '@/plugins/cartItemsMixin';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  const Cookie = process.client ? require('js-cookie') : undefined;

  export default {
    // async fetch() {
    //   const params = {
    //     limit: 1000,
    //     onlyRoot: true,
    //   };
    //   this.categories = (await this.$api.stores.getCategories(this.storeInformation._id, params)).data || [];
    // },
    components: {
      MPowerValidateInput: () => import('../MPowerValidateInput'),
      GlobalSearch: () => import('../typeahead/GlobalSearch'),
      BDropdownHover: () => import('../BDropdownHover'),
      CategoriesMenuItems: () => import('./CategoriesMenuItems'),
      BoxItems: () => import('../boxItems/index'),
      NavLink: () => import('./NavLink'),
      CartItems: () => import('../CartItems.vue'),
      CustomerCapture: () => import('@/components/CustomerCapture'),
      AvailableItems: () => import('./AvailableItems'),
    },
    data() {
      return {
        isPunchOutLoading: false,
        isPunchOut: false,
        // categories: [],
        showSearch: false,
        isAuthenticated: false,
        cartLoading: false,
        totalBoxItemsPrice: 0,
        boxItemsMinQuantity: 0,
        sideBarBoxItemsQuantity: 0,
        boxesLoaded: true,
        visibleDropdowns: {},
      };
    },
    mixins: [cartItemsMixin, orderAdjustmentsMixin],
    props: {
      parentContainer: {
        type: Object | Array,
        default: () => [],
        required: false,
      },
      container: {
        type: Object,
        default: () => [],
        required: false,
      },
      navbar: {
        type: Object,
        default: () => [],
        required: false,
      },
      logoContainer: {
        type: Object,
        default: () => [],
        required: true,
      },
      logo: {
        type: Object,
        default: () => [],
        required: true,
      },
      navLinksContainer: {
        type: Object,
        default: () => [],
        required: true,
      },
      navLinks: {
        type: Array,
        default: () => [],
        required: false,
      },
      productsList: {
        type: Array,
        default: () => [],
      },
      navUtilsContainer: {
        type: Object,
        default: () => [],
        required: true,
      },
      navUtils: {
        type: Array,
        default: () => [],
        required: false,
      },
      navCustomerContainer: {
        type: Object,
        default: () => ({}),
      },
      search: {
        type: Object,
        default: () => [],
        required: false,
      },
    },
    watch: {
      async boxSideBarVisible(val) {
        if (val) this.onShowSidebar('Box');
      },
      async cartSideBarVisible(val) {
        if (val) {
          this.onShowSidebar(this.sidebarCartType || 'Cart');
          this.checkPunchOut();
        }
      },
    },
    methods: {
      ...mapMutations({
        toggleBoxSidebar: 'sidebars/TOGGLE_BOX_SIDEBAR',
        hideCartSidebar: 'sidebars/HIDE_CART_SIDEBAR',
      }),
      ...mapActions('cart', ['loadOrders']),
      async checkPunchOut() {
        try {
          this.isPunchOutLoading = true;
          const { data } = await this.$axios.get(`/orders/${this.orders[0].order._id}/is-punch-out`);
          this.isPunchOut = data.isPunchOut;
        } catch (e) {
        } finally {
          this.isPunchOutLoading = false;
        }
      },
      async onShowSidebar(cartType) {
        let cartOrders = Cookie.get('cartOrders') || undefined;
        cartOrders = cartOrders ? JSON.parse(cartOrders) : [];
        const boxOrders = cartOrders.filter((i) => i.type === cartType);
        if (!this.boxOrdersLoaded || cartType === 'Cart') {
          const customerId = this.customer && this.customer._id;
          this.cartLoading = true;
          if (customerId) {
            await this.loadOrders({
              customerId,
              storeInformation: this.storeInformation,
              forceLoad: true,
            });
          } else if (boxOrders.length) {
            try {
              await this.loadOrders({
                orders: boxOrders.map((i) => i._id),
                customerId,
                onlyCount: false,
                storeInformation: this.storeInformation,
                forceLoad: true,
              });
            } catch (e) {}
          }
          this.cartLoading = false;
        }
      },
      async onCartLoad(orderId) {
        this.cartLoading = true;
        const customerId = this.customer && this.customer._id;
        await this.loadOrders({
          orders: [orderId],
          customerId,
          storeInformation: this.storeInformation,
          forceLoad: true,
          addToExistsOrders: true,
        });
        this.cartLoading = false;
      },
      onBoxesMinQuantityUpdate(value) {
        this.sideBarBoxItemsQuantity = value;
        this.boxItemsMinQuantity = value;
      },
      toBoxCheckout() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
        if (this.$refs.boxSidebar) {
          this.$refs.boxSidebar.hide();
        }
        this.$router.push('/boxcheckout');
        return;
      },
      toCartCheckout() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
        const route =
          this.sidebarCartType !== 'Cart' ? `/${this.sidebarCartType?.toLowerCase() || ''}checkout` : '/checkout';
        this.$router.push(route);
        if (this.$refs.cartSideBar) {
          this.$refs.cartSideBar.hide();
        }
      },
      showDropdown(index) {
        this.$set(this.visibleDropdowns, index, true);
      },
      hideDropdown(index) {
        this.$set(this.visibleDropdowns, index, false);
      },
      isDropdownVisible(index) {
        return !!this.visibleDropdowns[index];
      },
    },
    computed: {
      ...mapGetters({
        customer: 'auth/loggedInUser',
        storeCurrencySign: 'storeCurrencySign',
        unsavedBoxItems: 'cart/unsavedBoxItems',
        cartState: 'cart/cartState',
        cartSideBarVisible: 'sidebars/cartSideBarVisible',
        boxSideBarVisible: 'sidebars/boxSideBarVisible',
        sidebarCartType: 'sidebars/sidebarCartType',
      }),
      unpayableCart() {
        return ['Quote', 'Proof', 'Box'].includes(this.sidebarCartType) || this.storeInformation.hidePrices;
      },
      allNotSaved() {
        return this.boxItems.every((i) => !i.saved);
      },
      boxItems() {
        return [
          ...this.unsavedBoxItems.map((i) => ({ saved: false, data: _.cloneDeep(i) })),
          ...this.ordersByCartType('Box').map((i) => ({ saved: true, data: i })),
        ];
      },
      boxOrdersLoaded() {
        const orders = this.orders.filter((i) => i.orderStatus === 'Box Cart');
        return !orders.length || orders.every((i) => i.orderId);
      },
      visibleCustomerCapture() {
        const { carts = [] } = this.storeInformation;
        const cart = carts.find((i) => i.type === this.sidebarCartType);
        return !cart?.skipAddToCartPage;
      },
    },
  };
