import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a40f449a = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _8bc30406 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _5e9573de = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _249aa690 = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _7a757e1a = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _1642511e = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _1be297e2 = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _e79e4e50 = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _0e39bb2f = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _f840cf8c = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _1b416c5e = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _aafff328 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _778330ab = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _2b84f6e7 = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _a2ca2db2 = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _8a58db42 = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _81a931e6 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _15a7d706 = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _0313cc96 = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _3e50b3d4 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _c4932bec = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _082cfc0a = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _4328b2f8 = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _2906654f = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _87911696 = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _8eeebf1e = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _89e16d0e = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _e47e6d98 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _a97e5770 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _64527eba = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _d444a56e = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _e778958e = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _6ba8c46c = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _9554d79e = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _022b2496 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _07208638 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _c97da10c = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _1071f302 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _d9fa5bda = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _accf51ac = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _5ff33b0c = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _467a57ba = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _9ec7ffe2 = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _3b572302 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _da5b3e32 = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _39a46708 = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _063ad8f2 = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _50ab735c = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _9c26f284 = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _f8c6606e = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _47e8213a = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _7a37de86 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _49f4bda9 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _4d2d911e = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _b839548a = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _7fd2a5da = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _1957398c = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _03a148fe = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _4fbcc0ff = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _a40f449a,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _a40f449a,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _a40f449a,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _a40f449a,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _a40f449a,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _a40f449a,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _a40f449a,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _a40f449a,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _8bc30406,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _5e9573de,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _249aa690,
    name: "account"
  }, {
    path: "/cart",
    component: _a40f449a,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _7a757e1a,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _1642511e,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _1be297e2,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _e79e4e50,
    name: "designs"
  }, {
    path: "/facets",
    component: _0e39bb2f,
    name: "facets"
  }, {
    path: "/favorites",
    component: _f840cf8c,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _1b416c5e,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _aafff328,
    name: "health"
  }, {
    path: "/not-found",
    component: _778330ab,
    name: "not-found"
  }, {
    path: "/proof",
    component: _2b84f6e7,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _a2ca2db2,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _8a58db42,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _81a931e6,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _15a7d706,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _0313cc96,
    name: "signin"
  }, {
    path: "/signout",
    component: _3e50b3d4,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _c4932bec,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _082cfc0a,
    name: "testy"
  }, {
    path: "/version",
    component: _4328b2f8,
    name: "version"
  }, {
    path: "/version-app",
    component: _2906654f,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _87911696,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _8eeebf1e,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _89e16d0e,
    name: "products-utils"
  }, {
    path: "/sso/facebook/delete",
    component: _e47e6d98,
    name: "sso-facebook-delete"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _a97e5770,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _64527eba,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _8bc30406,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _5e9573de,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _d444a56e,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _e778958e,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _6ba8c46c,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _9554d79e,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _022b2496,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _07208638,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _c97da10c,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _1071f302,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _d9fa5bda,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _accf51ac,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _5ff33b0c,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _467a57ba,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _9ec7ffe2,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _3b572302,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _da5b3e32,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _39a46708,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _063ad8f2,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _50ab735c,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _9c26f284,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _f8c6606e,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _47e8213a,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _7a37de86,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _49f4bda9,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _4d2d911e,
    name: "orders-id-track-shipment"
  }, {
    path: "/products/:slug?/reviews",
    component: _b839548a,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _7fd2a5da,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _1957398c,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _03a148fe,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _4fbcc0ff,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
