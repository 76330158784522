
  import { mapGetters } from 'vuex';
  import auth from '@/plugins/authMixins';
  import dataLayerMixin from '@/plugins/dataLayer.mixin.js';

  export default {
    name: 'CustomerCapture',
    data() {
      return {
        visibleSignin: false,
        customerData: {
          email: null,
          password: null,
        },
        newCustomer: {
          firstName: null,
          lastName: null,
          email: null,
          password: null,
        },
        newCustomerErrorMsg: '',
        submitting: false,
        saveState: false,
        error: '',
      };
    },
    props: {
      formTitle: String,
      formBody: String,
      redirect: String,
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
        isAuthenticated: 'auth/isAuthenticated',
        storeCurrencySign: 'storeCurrencySign',
      }),
      cartRoute() {
        const { carts = [] } = this.storeInformation;
        const cart = carts.find((i) => i.type === this.cartType);
        return cart ? cart.route : 'cart';
      },
    },
    mixins: [auth, dataLayerMixin],
    methods: {
      toggleSignin() {
        this.visibleSignin = !this.visibleSignin;
      },
      loginAndToCart(customer) {
        this.logIn(
          Object.assign(
            {},
            customer,
            { redirect: this.redirect || `/${this.cartRoute}` },
            { storeUrl: this.storeInformation.storeUrl }
          )
        );
        this.$emit('redirect');
      },
      async signIn() {
        this.saveState = true;
        this.loginAndToCart(this.newCustomer);
        this.$emit('saveState', this.saveState);
      },
      async submitCreateCustomer() {
        try {
          await this.$refs.createAccount.$validator.validateAll();
          if (this.$refs.createAccount.formIsInvalid) return;
          this.submitting = true;
          this.customerData.distributorId = this.storeInformation.distributorId;
          this.customerData.stores = [this.storeInformation._id];
          this.customerData.source = 'Web';
          const data = (await this.$api.customers.createCustomer(this.customerData)).data.model;
          await this.$api.customers.addCustomerDevice(data);
          this.$api.customers.addCustomerCreateAccountDevice(data._id);
          this.saveState = true;
          this.loginAndToCart(this.customerData);
          this.onDataLayerSignUp(data);
        } catch (err) {
          if (err.response.data && err.response.data.$$notification) {
            if (err.response.data.$$notification.error.match(/email/i)) {
              this.newCustomerErrorMsg =
                'We found an account that matches the email you enterd.  Please sign in below or click "Forgot Password?" to reset your password.';
            }
          }
          this.submitting = false;
        }
        this.$emit('saveState', this.saveState);
      },
    },
    components: {
      createAccount: () => import('./cartitemsadded/CreateAccountForm'),
      signin: () => import('./cartitemsadded/SignInForm'),
    },
  };
